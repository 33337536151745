:root{
  --mainBlue: #2a2a99;
  --lightBlue: #009ffd;
  --mainWhite: #f3f3f3;
  --mainDark: #232528;
  --mainYellow: #ffa400;
}

body {
  font-family: 'Lato', sans-serif!important;
  background: var(--mainWhite)!important;
  color: var(--mainDark)!important;
}

.text-title {
  font-family: 'Arimo', sans-serif!important;
}

.text-details {
  color: var(--mainDark)
}